import React, { memo } from 'react'
import PropTypes from 'prop-types'

import NotificationFeedStyled from './styledComponent'

const NotificationFeed = (props) => {
  const getClassName = () => {
    let className = props.className ? props.className : ''
    if (props.unread) {
      className += ' un-read'
    }
    return className
  }

  const handleNotificationClicked = () => {
    props.onClick()
  }

  return (
    <NotificationFeedStyled className={getClassName()} onClick={() => handleNotificationClicked()} id="list-content-noti">
      <div className="title text-style prevent-text-overflow" id="lbl-title-noti">
        {props.title}
      </div>
      <div className="message text-style prevent-text-overflow" id="lbl-detail-noti">
        {props.message}
      </div>
    </NotificationFeedStyled>
  )
}

NotificationFeed.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  unread: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default memo(NotificationFeed)
