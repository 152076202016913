import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 80px;
  background: ${(props) => props.theme.darkBackground};
  transition: 0.1s;
  padding: 20px;
  cursor: pointer;
  &.un-read {
    background: ${(props) => props.theme.greyNotificationRead};
    .title {
      font-family: 'Prompt', sans-serif;
      color: white;
      height: 20px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.11;
      margin-bottom: 10px;
    }
  }
  .title {
    font-family: 'Prompt', sans-serif;
    color: white;
    height: 20px;
    font-size: 12px;
    line-height: 1.11;
    margin-bottom: 10px;
  }
  .message {
    font-family: 'Prompt', sans-serif;
    font-size: 10px;
    font-style: italic;
    color: white;
  }
`
